import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature0 from '../../compoment/Feature0';//功能
import Footer1 from '../../compoment/Footer1';//页脚单独引
import { Footer10DataSource } from '../../data/head.source'//页脚单独引
import news1 from '../../assets/img/news1.jpg'
import rongguang from '../../assets/img/rongguang.jpg'
import nianhui from '../../assets/img/nianhui.jpg'
import tongzhi from '../../assets/img/tongzhi.jpg'
import xiuxn from '../../assets/img/202409xixun.jpg'
import xibao from '../../assets/img/202409xibao.jpg'
import tuanjian from '../../assets/img/202409tuanjian.jpg'
import {
    nSource,//1

} from '../../data/news.source';
import '../../assets/less/antMotionStyle.less';
import News from '../../compoment/News'
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            newsdata: [
                //https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E5%B9%B4%E5%BA%A6%E8%82%A1%E4%B8%9C%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf
                // https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802025%E5%B9%B4%E7%AC%AC%E4%B8%80%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf
                {
                    img: tongzhi,
                    date: '2025-04-01',
                    title: '股东会通知',
                    msg: "关于召开2024年年度股东会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E5%B9%B4%E5%BA%A6%E8%82%A1%E4%B8%9C%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: tongzhi,
                    date: '2025-03-06',
                    title: '股东会通知',
                    msg: "关于召开2025年第一次临时股东会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802025%E5%B9%B4%E7%AC%AC%E4%B8%80%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: news1,
                    date: '2025-01-28',
                    title: '金蛇献瑞',
                    msg: "新年贺词 ",
                    url: 'https://www.eeechina.cn/pdf/76c8b879818794e9129126a41f3ac95.jpg'
                },
            
                {
                    img: news1,
                    date: '2025-01-22',
                    title: '感谢信',
                    msg: "客户的认可是我们最大的动力 ",
                    url: 'https://www.eeechina.cn/pdf/%E8%A1%A8%E6%89%AC%E4%BF%A1.pdf'
                },
                {
                    img: news1,
                    date: '2025-01-18',
                    title: '公司讯',
                    msg: "北京东润环能『疾风大模型』即将发布 ",
                    url: 'https://mp.weixin.qq.com/s/wO1XbLuIzN0oSWrtp_WmrQ?token=198408922&lang=zh_CN'
                },
                {
                    img: news1,
                    date: '2025-01-02',
                    title: '业绩快报',
                    msg: "业绩快报 ",
                    url: 'https://www.eeechina.cn/pdf/%E4%B8%9A%E7%BB%A9%E5%BF%AB%E6%8A%A5.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-12-09',
                    title: '股东会通知',
                    msg: "关于召开2024年第六次临时股东会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E5%85%AD%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },

                {
                    img: news1,
                    date: '2024-11-29',
                    title: '凝心聚力启新程，创新创业谱新篇',
                    msg: "东润数字能源首届合伙人代表大会圆满召开 ",
                    url: 'https://www.eeechina.cn/pdf/%E5%87%9D%E5%BF%83%E8%81%9A%E5%8A%9B%E5%90%AF%E6%96%B0%E7%A8%8B%20%E5%88%9B%E6%96%B0%E5%88%9B%E4%B8%9A%E8%B0%B1%E6%96%B0%E7%AF%87.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-10-30',
                    title: '股东大会通知',
                    msg: "关于召开2024年第五次临时股东大会的通知 ",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E4%BA%94%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-10-13',
                    title: '股东大会通知',
                    msg: "关于召开2024年第四次临时股东大会的通知 ",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E5%9B%9B%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },

                {
                    img: tongzhi,
                    date: '2024-09-15',
                    title: '股东大会通知',
                    msg: "关于召开2024年第三次临时股东大会的通知 ",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E4%B8%89%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: xiuxn,
                    date: '2024-09-04',
                    title: '喜讯 | 东润环能成功通过CMMI5认证，迈向卓越管理新高度 ',
                    msg: "喜讯 | 东润环能成功通过CMMI5认证，迈向卓越管理新高度 ",
                    url: 'https://mp.weixin.qq.com/s/qkZTD0aPEKA-jpArKw2wPw'
                },
                {
                    img: xibao,
                    date: '2024-09-03',
                    title: '喜报 | 北京东润环能荣获国家专精特新“小巨人”企业称号 ',
                    msg: "喜报 | 北京东润环能荣获国家专精特新“小巨人”企业称号 ",
                    url: 'https://mp.weixin.qq.com/s/9H_0eMZETvqdDSk3ZRlcVQ'
                },
                {
                    img: tuanjian,
                    date: '2024-09-02',
                    title: '勇攀高峰，追求卓越 | 北京东润环能2024年白石山团建 ',
                    msg: "勇攀高峰，追求卓越 | 北京东润环能2024年白石山团建 ",
                    url: 'https://mp.weixin.qq.com/s/GQL2iSa0HMVNXB6cG7coGw'
                },
                {
                    img: news1,
                    date: '2024-07-18',
                    title: '海淀法院驳回汤某全部诉讼请求 ',
                    msg: "海淀法院驳回汤某全部诉讼请求 ",
                    url: 'https://www.eeechina.cn/pdf/%E6%B5%B7%E6%B7%80%E6%B3%95%E9%99%A2%E9%A9%B3%E5%9B%9E%E6%B1%A4%E6%9F%90%E5%85%A8%E9%83%A8%E8%AF%89%E8%AE%BC%E8%AF%B7%E6%B1%82.pdf'
                },
                //
                {
                    img: news1,
                    date: '2024-05-24',
                    title: '海淀法院一审驳回汤某全部诉讼请求 ',
                    msg: "海淀法院一审驳回汤某全部诉讼请求 ",
                    url: 'https://www.eeechina.cn/pdf/%E4%B8%9C%E6%B6%A6%E7%8E%AF%E8%83%BD%E8%B5%84%E8%AE%AF%EF%BC%9A%E6%B5%B7%E6%B7%80%E6%B3%95%E9%99%A2%E4%B8%80%E5%AE%A1%E9%A9%B3%E5%9B%9E%E6%B1%A4%E6%9F%90%E5%85%A8%E9%83%A8%E8%AF%89%E8%AE%BC%E8%AF%B7%E6%B1%82.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-04-08',
                    title: '股东大会通知',
                    msg: "关于召开 2023 年年度股东大会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802023%E5%B9%B4%E5%B9%B4%E5%BA%A6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: news1,
                    date: '2024-04-08',
                    title: '董事会全票通过《2023 年年度报告》，营业收入再创新高 ',
                    msg: "公司董秘办 2024 年 4 月 8 日讯：公司于 2024 年 3 月 30 日召开第三届董事会 第三十六次会议，全票通过了《2023 年年度报告》（全国股转公司模板内容格式）等相关议案",
                    url: 'https://www.eeechina.cn/pdf/%E4%B8%9C%E6%B6%A6%E7%8E%AF%E8%83%BD%E8%B5%84%E8%AE%AF%EF%BC%9A%E8%91%A3%E4%BA%8B%E4%BC%9A%E5%85%A8%E7%A5%A8%E9%80%9A%E8%BF%87%E3%80%8A2023%E5%B9%B4%E5%B9%B4%E5%BA%A6%E6%8A%A5%E5%91%8A%E3%80%8B%EF%BC%8C%E4%B8%9A%E5%8A%A1%E6%94%B6%E5%85%A5%E5%86%8D%E5%88%9B%E6%96%B0%E9%AB%98.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-02-07',
                    title: '股东大会通知',
                    msg: "关于召开2024年第二次临时股东大会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E4%BA%8C%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: tongzhi,
                    date: '2024-02-05',
                    title: '股东大会通知',
                    msg: "关于召开2024年第一次临时股东大会的通知",
                    url: 'https://www.eeechina.cn/pdf/%E5%85%B3%E4%BA%8E%E5%8F%AC%E5%BC%802024%E5%B9%B4%E7%AC%AC%E4%B8%80%E6%AC%A1%E4%B8%B4%E6%97%B6%E8%82%A1%E4%B8%9C%E5%A4%A7%E4%BC%9A%E7%9A%84%E9%80%9A%E7%9F%A5.pdf'
                },
                {
                    img: nianhui,
                    date: '2024-02-03',
                    title: '笃行致远 惟实励新 | 东润环能2023总结表彰大会暨联欢晚会圆满结束',
                    msg: "2024年2月2日，北京东润环能2023总结表彰大会暨2024联欢晚会在北京蓝地时尚庄园酒店隆重召开，总结2023年的工作与成绩，表彰各部门、各业务涌现出的先进与标兵。",
                    url: 'https://mp.weixin.qq.com/s/AH4mcX-aWicOwx3rs8_Slw'
                },
                {
                    img: rongguang,
                    date: '2024-01-28',
                    title: '客户点赞，东润荣光',
                    msg: "公司讯：2023年12月，我司陆续收到来自各级、各地电网公司、发电集团等多家客户方的表扬信和感谢信。来信充分认可我司产品研发、技术支持团队精湛的业务技术能力与贴心到位的优异服务，同时也对我司较高的产品性能给予了充分肯定。",
                    url: 'https://mp.weixin.qq.com/s/CwKCZJ8hg41WRl-GPzImJg'
                },
                {
                    img: news1,
                    date: '2024-01-25',
                    title: '东方宇华出售浑源49%股权价款于2023年12月28日收妥',
                    msg: "公司董秘办 2024 年 1 月 22 日讯：公司于 2023 年 11 月 17 日召开第三届董 事会第三十二次会议，全票通过了《关于子公司转让参股公司股权的议案》，详见公司于 2023 年 11 月 21 日在全国中小企业股份转让系统有限责任公司官网（www.neeq.com.cn）披露的《出售资产公告》（公告编号：2023-076）。",
                    url: 'https://www.eeechina.cn/pdf/%E4%B8%9C%E6%B6%A6%E7%8E%AF%E8%83%BD%E8%B5%84%E8%AE%AF%EF%BC%9A%E4%B8%9C%E6%96%B9%E5%AE%87%E5%8D%8E%E5%87%BA%E5%94%AE%E6%B5%91%E6%BA%9049%25%E8%82%A1%E6%9D%83%E4%BB%B7%E6%AC%BE%E4%BA%8E2023%E5%B9%B4%E5%BA%95%E5%89%8D%E6%94%B6%E5%A6%A5.pdf'
                },

            ]

        };
        this.pdfPath = '../../assets/pdf/东润环能资讯：东方宇华出售浑源49%股权价款于2023年底前收妥 - 清洁版.pdf';
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });

    }

    render () {
        const children = [
            <Banner3
                key="news_0"
                dataSource={nSource}
                isMobile={this.state.isMobile}
                style={{ height: '50vh' }}
            />,
            <News key="newsindex" style={{ marginBottom: '100px' }} data={this.state.newsdata} />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }} >
                {children}
            </div>
        );
    }
}
